import React from 'react'
import { CFImage, CFLink, CFView } from 'components'
import { cflogo } from 'images'

export default () => {
  return (
    <CFView column center h="65px">
      <CFView h6 color="black" content>
        COPYRIGHT © 2025 CODEFUSION INC.
      </CFView>
      <CFLink href="https://codefusion.tech" newTab>
        <CFImage h="25px" pt="5px" src={cflogo} alt="CodeFusion" />
      </CFLink>
    </CFView>
  )
}
